<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? data.name || "Фото-отчет" : "Новая запись" }}
        <v-btn-toggle v-if="showComment" v-model="tab" mandatory borderless dense>
          <v-btn :value="'tab-report'" color1="#d0bf9c">
            <span class="hidden-lg-and-down">Отчет</span>
            <v-icon :right="$vuetify.breakpoint.lgAndUp"> far fa-file-alt </v-icon>
          </v-btn>
          <v-btn :value="'tab-comments'" class="ml-1" color1="#d0bf9c">
            <span class="hidden-lg-and-down">Комментарии</span>
            <v-icon :right="$vuetify.breakpoint.lgAndUp"> far fa-comments </v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>
      <v-tabs v-if="showComment && !true" v-model="tab" background-color="transparent" slider-color="white">
        <v-tab v-for="(t, i) in tabs" v-if="t.show" :href="`#tab-${t.name}`" :key="i" class="mr-3">
          {{ t.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
        <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
          <div v-if="t.name == 'report'" class="pl-1">
            <a-form-model v-if="editMode" v-model="data" :model="model" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
            <div v-else>
              <a-form-view v-model="data" :model="getFormModel(['description'])" :config="{ dense: true }" />
              <a-view-images :value="data.photos" />
            </div>
          </div>
          <v-card v-if="t.name == 'comments'">
            <v-card-title class="py-1">Коментарии</v-card-title>
            <comment-view :id="id || 0" :height="$root.windowSize.contentHeight - 60" :targetName="'construction_reports'" :needUpdate="needUpdateComments" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <template v-if="tab == 'tab-report'" v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="getAccess('photoReport.edit', { users: [data.createdby_id] }) && editMode" @click="submit()" />
        <a-btn-delete v-if="id && editObject && editMode" @click="removeDialogShow = true" />
        <a-btn-edit v-show="!editMode && getAccess('photoReport.edit', { users: [data.createdby_id] })" @click="editMode = true" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel, comments } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel, comments],
  props: {
    value: Boolean,
    id: Number,
    object_: { type: Object, default: () => {} },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.objectReports,
      api: "/mechti/construction-reports",
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить этот отчет?",
      idEdit: 0,
      suEditShow: false,
      editMode: false,
      tab: "tab-report",
    };
  },
  computed: {
    showComment() {
      return this.getAccess(`photoReport.comments`, null, true) && !this.editMode && this.id;
    },
    tabs() {
      return [
        { name: "report", title: "Фото-отчет", show: true },
        { name: "comments", title: "Комментарии", show: this.showComment },
      ];
    },
    model() {
      let model = this.getModelList(this.m, "form");
      model.find(m => m.name == "client_visible").hidden = !this.getAccess("photoReport.client_visible");
      return model;
    },
    editObject() {
      return this.getAccess("object.edit", {
        id: this.object_?.id,
        access: this.object_?.object_access || [],
      });
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.editMode = false;
        this.tab = "tab-report";
        this.updateData(this.id);
        if (!this.id) {
          this.editMode = true;
          this.data.object_id = this.object_.id;
          //  this.data.status = 0;
        }
      }
    },
  },
  created() {
    if (this.value) {
      this.editMode = false;
      this.updateData(this.id);
      if (!this.id) {
        this.editMode = true;
        this.data.object_id = this.object_.id;
        //  this.data.status = 0;
      }
    }
  },
  methods: {
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
